import React, { Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import RegistrationForm from './RegistrationForm';
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from 'react-responsive-carousel';
import './style.css';
import Button from '@material-ui/core/Button';
import BackgroundSlider from 'react-background-slider'
import {
    GetCategorias
} from '../../utils/CategoriasManager';
function Page(props) {
    const {
        goTo,
        currentItem,
    } = props;
    const VerActividades = () =>{
        document.getElementById('actividades').scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
    }

    return (
        <Fragment>
            <CssBaseline />
            <div className="details-page" style={{marginTop:'5%',display: 'flex',flexWrap: 'wrap'}}>
            <RegistrationForm />
            </div>
        </Fragment>
    );
}

export default Page;