import React from 'react';

const SplashLoader = ({ loading }) => {
  if (!loading) return null; // Si no está cargando, no renderiza nada

  // Componente de splash loader
  return (
    <div style={styles.loaderContainer}>
      <div style={styles.spinner}></div>
      <h2 style={styles.text}>Cargando...</h2>
    </div>
  );
};

// Estilos en línea para el loader
const styles = {
  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    zIndex: 9999,
  },
  spinner: {
    border: '8px solid #f3f3f3',
    borderRadius: '50%',
    borderTop: '8px solid #3498db',
    width: '50px',
    height: '50px',
    animation: 'spin 1s linear infinite',
  },
  text: {
    marginTop: '15px',
    fontSize: '18px',
    color: '#555',
  },
};

// Añadir la animación de rotación
const styleElement = document.createElement('style');
styleElement.innerHTML = `
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
document.head.appendChild(styleElement);

export default SplashLoader;
