import React, { Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import MaterialTable from 'material-table';
import { FaWhatsapp } from 'react-icons/fa'; // Ícono de WhatsApp desde react-icons

function Page(props) {
    const {
        // goTo,
        // currentItem,
    } = props;
    const [state, setState] = React.useState({
      columns: [
        { title: 'Id', field: 'id',lookup: props.categoriasLookUp},
        
        { title: 'Nombre', field: 'nombre', type:'date' },
        { title: 'Apellido', field: 'apellido', type: 'numeric' },
        {
          title: 'DNI',
          field: 'dni'
        },
        { title: 'Notas', 
          field: 'notas'
        },
        { title: 'Celular', field: 'celular' },
        { title: 'Email', field: 'email' },
        { title: 'Fecha ultimo pago', field: 'UltimoPeriodoPago' }
      ],
      data: props.sociosMorosos,
    });
  
    
    const openWhatsApp = (phoneNumber) => {
      // Sanitizar el número de teléfono para que solo queden los dígitos
      const sanitizedNumber = phoneNumber.replace(/\D/g, '');
    
      // Crear la URL de wa.me con el número de teléfono
      const whatsappUrl = `https://wa.me/${sanitizedNumber}`;
    
      // Abrir en una nueva pestaña
      window.open(whatsappUrl, '_blank');
    };
    
    return (
        <Fragment>
            <CssBaseline />
            <div className="details-page" style={{ marginTop:'5%',display: 'flex',flexWrap: 'wrap'}}>

            <div class="break" style={{flexBasis: '100%'}}></div> 
            <div class="break" style={{flexBasis: '100%'}}></div> 
            <MaterialTable
      title="Socios Morosos"
      columns={state.columns}
      data={state.data}
      actions={[
        {
          icon: 'payment',
          tooltip: 'Ver pagos',
          onClick: (event, rowData) => {window.location.href = "/socio-pagos?idSocio="+rowData.id+"&nombre="+rowData.nombre+" "+rowData.apellido}
        },
        rowData => ({
          icon: () => <FaWhatsapp color={!rowData.celular || rowData.celular == 'undefined'? 'gray' : 'green'}  />, // Ícono de WhatsApp
          tooltip:  !rowData.celular || rowData.celular == 'undefined'? 'WhatsApp no disponible' : 'Abrir WhatsApp',
          onClick: (event, rowData) => {openWhatsApp(rowData.celular)},
          disabled: !rowData.celular || rowData.celular == 'undefined'
        })
      ]}
      style={{width:'100%'}}
    
    />
            </div>
        </Fragment>
    );
}

export default Page;