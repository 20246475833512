
const API_URL = "https://climagym.com.ar/api/";
const GetRequest= (url) =>{
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open( "GET", url, false ); // false for synchronous request
    xmlHttp.send( null );
    return JSON.parse(xmlHttp.responseText);
}
export const GetSocios = () =>{
    try{
        return GetRequest(API_URL+"socios.php");
    }catch(e){
        return []
    }

}
export const GetSociosMorosos = () =>{
    try{
        return GetRequest(API_URL+"morosos.php");
    }catch(e){
        return []
    }

}
export const DeleteTurno = (id) =>{
    try{
        return GetRequest(API_URL+"deleteTurno.php?id="+id);
    }catch(e){
        return []
    }

}
export const GetSocioDNI = (dni) =>{
    try{
        return GetRequest(API_URL+"socioDNI.php?dni="+dni);
    }catch(e){
        return []
    }

}
export const PostSocio = (socio) =>{
    try{
    var request = new XMLHttpRequest();
    request.open("POST", API_URL+"postSocio.php", false);
    var formData = new FormData();
    formData.append("nombre", socio.nombre);
    formData.append("apellido", socio.apellido);
    formData.append("notas", socio.notas);
    formData.append("dni", socio.dni);
    formData.append("celular", socio.celular);
    formData.append("email", socio.email);


    request.send(formData);
    return JSON.parse(request.responseText); 
    }catch(e){
        return null
    }

}
export const PutSocio = (socio) =>{
    try{
    var request = new XMLHttpRequest();
    request.open("POST", API_URL+"putSocio.php");
    var formData = new FormData();
    formData.append("id", socio.id);
    formData.append("nombre", socio.nombre);
    formData.append("apellido", socio.apellido);
    formData.append("notas", socio.notas);
    formData.append("dni", socio.dni);
    formData.append("celular", socio.celular);
    formData.append("email", socio.email);


    request.send(formData);
    return JSON.parse(request.responseText); 
    }catch(e){
        return null
    }

}
export const CreateOrUpdateSocio = (socio) => {
    try {
        var request = new XMLHttpRequest();
        // Hacer la request sincrónica pasando 'false' como tercer argumento
        request.open("POST", API_URL + "createOrUpdateSocio.php", false);

        // Crear el FormData con los datos del socio
        var formData = new FormData();
        formData.append("id", socio.id);
        formData.append("nombre", socio.nombre);
        formData.append("apellido", socio.apellido);
        formData.append("notas", socio.notas);
        formData.append("dni", socio.dni);
        formData.append("celular", socio.celular);
        formData.append("email", socio.email);
        formData.append("genero", socio.genero);
        formData.append("fechaNacimiento", socio.fechaNacimiento);

        // Enviar la solicitud de forma sincrónica
        request.send(formData);

        // Revisar el status de la respuesta
        if (request.status === 200) {
            return true; // Éxito
        } else {
            return false; // Error con otro status code
        }
    } catch (e) {
        // Si ocurre una excepción, devolver false
        return false;
    }
};

export const DeleteSocio = (id) =>{
    try{
        return GetRequest(API_URL+"deleteSocio.php?id="+id);
    }catch(e){
        return []
    }

}