import React, { useState } from 'react';
import { TextField, Button, Grid, Box, InputAdornment, FormControlLabel, Radio, RadioGroup, FormControl, FormLabel, Checkbox, FormGroup } from '@material-ui/core';
import { AccountCircle, Email, Phone, AssignmentInd, Event } from '@material-ui/icons';
import { GetCategorias } from '../../utils/CategoriasManager';
import { CreateOrUpdateSocio } from '../../utils/SocioManager';
import SplashLoader from '../common/SplashLoader';
function RegistrationForm() {
    const [formData, setFormData] = useState({
        nombre: '',
        apellido: '',
        dni: '',
        celular: '',
        email: '',
        fechaNacimiento: '',
        genero: '',
        actividades: []
    });

    const categorias = GetCategorias();
    const [errors, setErrors] = useState({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(null);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

        if (isSubmitted) {
            validateField(name, value);
        }
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        let updatedActivities = [...formData.actividades];

        if (checked) {
            updatedActivities.push(name);
        } else {
            updatedActivities = updatedActivities.filter(activity => activity !== name);
        }

        setFormData({
            ...formData,
            actividades: updatedActivities
        });
    };

    // Función para sanitizar el teléfono y añadir prefijo de Argentina +54
    const sanitizePhone = (telefono) => {
        // Remover cualquier carácter que no sea un número
        let sanitizedPhone = telefono.replace(/\D/g, '');
    
        // Verificar si el número empieza con '15' (móvil de Buenos Aires)
        if (sanitizedPhone.startsWith('15') && sanitizedPhone.length === 10) {
            sanitizedPhone = '11' + sanitizedPhone.slice(2);  // Reemplazar el '15' por '11'
        }
    
        // Si empieza con '11' (Buenos Aires), debe tener 10 dígitos en total
        const buenosAiresRegex = /^11\d{8}$/;
    
        // Prefijos provinciales (código de área de 3 o 4 dígitos, seguido de 6 o 7 dígitos)
        const provinciaRegex = /^(?:2\d{2}|3\d{2}|6\d{2}|8\d{2}|2\d{3})\d{6,7}$/;
    
        // Validar si el número cumple con el formato de Buenos Aires o provincial
        if (buenosAiresRegex.test(sanitizedPhone) || provinciaRegex.test(sanitizedPhone)) {
            // Agregar prefijo de Argentina
            return `54${sanitizedPhone}`;
        } else {
            return false;  // Número inválido
        }
    };
    const validateField = (name, value) => {
        let tempErrors = { ...errors };

        switch (name) {
            case 'nombre':
                tempErrors.nombre = value ? "" : "El nombre es requerido.";
                break;
            case 'apellido':
                tempErrors.apellido = value ? "" : "El apellido es requerido.";
                break;
            case 'dni':
                tempErrors.dni = /^\d+$/.test(value) && value.length <= 8 && parseInt(value) > 0
                    ? ""
                    : "El DNI debe contener solo números, tener máximo 8 dígitos y no ser negativo.";
                break;
            case 'celular':
                const sanitizedPhone = sanitizePhone(value);
                tempErrors.celular = sanitizedPhone ? "" : "El celular no es válido para Argentina.";
                break;
            case 'email':
                tempErrors.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(value) ? "" : "Email no válido.";
                break;
            case 'fechaNacimiento':
                tempErrors.fechaNacimiento = value ? "" : "La fecha de nacimiento es requerida.";
                break;
            case 'genero':
                tempErrors.genero = value ? "" : "El género es requerido.";
                break;
            default:
                break;
        }

        setErrors(tempErrors);
    };

    const validate = () => {
        let tempErrors = {};
        tempErrors.nombre = formData.nombre ? "" : "El nombre es requerido.";
        tempErrors.apellido = formData.apellido ? "" : "El apellido es requerido.";
        tempErrors.dni = /^\d+$/.test(formData.dni) && formData.dni.length <= 8 && parseInt(formData.dni) > 0
            ? ""
            : "El DNI debe contener solo números, tener máximo 8 dígitos y no ser negativo.";
        tempErrors.celular = sanitizePhone(formData.celular) ? "" : "El teléfono no es válido para Argentina.";
        tempErrors.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(formData.email) ? "" : "Email no válido.";
        tempErrors.fechaNacimiento = formData.fechaNacimiento ? "" : "La fecha de nacimiento es requerida.";
        tempErrors.genero = formData.genero ? "" : "El género es requerido.";

        setErrors(tempErrors);
        return Object.values(tempErrors).every(x => x === "");
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        setLoading(true);
        if (validate()) {

            const actividadesSeleccionadas = formData.actividades.join(', ');
            const socio = {
                ...formData,
                notas: actividadesSeleccionadas,
                celular: sanitizePhone(formData.celular) // Enviar teléfono ya sanitizado             
            }
            console.log(socio, 'socio');
            const result = CreateOrUpdateSocio(socio);
            setIsSuccess(result);
        }
        setLoading(false);
    };

    return (
        <>
        {isSuccess !== null && <center><h1>{isSuccess ? 'Datos guardados correctamente': 'Ha ocurrido un error por favor intentalo de nuevo mas tarde.'}</h1>        </center> }
        {isSuccess == null &&
            <Box component="form" onSubmit={handleSubmit} style={{ marginTop: '24px' }}>
            <SplashLoader loading={loading} />
            <Grid container spacing={2}>
                {/* Nombre */}
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Nombre"
                        name="nombre"
                        variant="outlined"
                        value={formData.nombre}
                        onChange={handleChange}
                        error={!!errors.nombre}
                        helperText={errors.nombre}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                {/* Apellido */}
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Apellido"
                        name="apellido"
                        variant="outlined"
                        value={formData.apellido}
                        onChange={handleChange}
                        error={!!errors.apellido}
                        helperText={errors.apellido}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AccountCircle />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                {/* DNI */}
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="DNI"
                        name="dni"
                        type="number"
                        variant="outlined"
                        value={formData.dni}
                        onChange={handleChange}
                        error={!!errors.dni}
                        helperText={errors.dni}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AssignmentInd />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                {/* Celular */}
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Celular"
                        name="celular"
                        type="number"
                        variant="outlined"
                        value={formData.celular}
                        onChange={handleChange}
                        error={!!errors.celular}
                        helperText={errors.celular}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Phone />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                {/* Email */}
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Email"
                        name="email"
                        variant="outlined"
                        value={formData.email}
                        onChange={handleChange}
                        error={!!errors.email}
                        helperText={errors.email}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Email />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                {/* Fecha de Nacimiento */}
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Fecha de Nacimiento"
                        name="fechaNacimiento"
                        type="date"
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        value={formData.fechaNacimiento}
                        onChange={handleChange}
                        error={!!errors.fechaNacimiento}
                        helperText={errors.fechaNacimiento}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Event />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                {/* Género */}
                <Grid item xs={12} sm={6}>
                    <FormControl component="fieldset" error={!!errors.genero}>
                        <FormLabel component="legend">Género</FormLabel>
                        <RadioGroup
                            name="genero"
                            value={formData.genero}
                            onChange={handleChange}
                        >
                            <FormControlLabel value="masculino" control={<Radio />} label="Masculino" />
                            <FormControlLabel value="femenino" control={<Radio />} label="Femenino" />
                            <FormControlLabel value="otro" control={<Radio />} label="Otro" />
                        </RadioGroup>
                    </FormControl>
                    {errors.genero && <p style={{ color: 'red' }}>{errors.genero}</p>}
                </Grid>
                {/* Actividades de interés */}
                <Grid item xs={12}>
                    <h3>Actividades de interés:</h3>
                    <FormGroup>

                        {categorias.filter(c => c.mostrar == 1).map(c => (<FormControlLabel
                            control={
                                <Checkbox
                                    checked={formData.actividades.includes(c.nombre)}
                                    onChange={handleCheckboxChange}
                                    name={c.nombre}
                                    color="primary"
                                />
                            }
                            label={c.nombre}
                        />))}
                    </FormGroup>
                </Grid>

                {/* Botón de envío */}
                <Grid item xs={12}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                    >
                        Registrarse
                    </Button>
                </Grid>
            </Grid>
        </Box>
        }
        
        </>
    );
}

export default RegistrationForm;
