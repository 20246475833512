
const API_URL = "https://climagym.com.ar/api/";
const GetRequest= (url) =>{
    var xmlHttp = new XMLHttpRequest();
    xmlHttp.open( "GET", url, false ); // false for synchronous request
    xmlHttp.send( null );
    return JSON.parse(xmlHttp.responseText);
}
export const GetPagos = (idSocio) =>{
    try{
        if(idSocio)
        return GetRequest(API_URL+"getPagos.php?idSocio="+idSocio);

        return GetRequest(API_URL+"getPagos.php");

    }catch(e){
        return []
    }

}
export const GetGastos = (idSocio) =>{
    try{
        if(idSocio)
        return GetRequest(API_URL+"getGastos.php?idSocio="+idSocio);

        return GetRequest(API_URL+"getGastos.php");

    }catch(e){
        return []
    }

}
export const GetReporte = (mes, anio) =>{
    try{

        return GetRequest(API_URL+"reporte-mensual.php?mes="+mes+"&anio="+anio);

    }catch(e){
        return []
    }

}
export const PostPago = (pago) =>{
    try{
    var request = new XMLHttpRequest();
    request.open("POST", API_URL+"postPago.php", false);
    var formData = new FormData();

    if(pago.idSocio)
    formData.append("idSocio", pago.idSocio);

    if(pago.tipoPago)
    formData.append("tipoPago", 2);

    formData.append("idCategoria", pago.idCategoria);
    formData.append("periodo", pago.periodo);
    formData.append("monto", pago.monto);
    formData.append("descripcion", pago.descripcion);
    formData.append("formaPago", pago.formaPago);

    request.send(formData);
    var jsonText = request.responseText;
    console.log('request',jsonText);
    return JSON.parse(jsonText); 
    }catch(e){

        console.log('lpm pago',e);
        return null
    }

}
export const DeletePago = (id) =>{
    try{
        return GetRequest(API_URL+"deletePago.php?id="+id);
    }catch(e){
        return []
    }

}