import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Home  from './components/home';
import Socios from './components/socios';
import SocioPagos from './components/socio-pagos';

import Categoria from './components/categoria';
import Inscripcion from './components/inscripcion';
import Actividades from './components/actividades';
import Actividad from './components/actividad';
import Login from './components/login';
import Morosos from './components/morosos';
import Reporte from './components/reporte';


import MisActividades from './components/mis-actividades';

import CrearActividad from './components/crear-actividad';
import EditarActividad from './components/editar-actividad';
import Ingresos from './components/ingresos';
import Gastos from './components/gastos';
import Grilla from './components/grilla';
import GrillaMobile from './components/grilla-mobile'
import Registro from './components/registro';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
// import store from './redux/store';


const Root = (
    // <Provider store={store}>
        <BrowserRouter>
            <Switch>
                <Route path="/home" component={Home} />
                <Route path="/registro" component={Registro} />
                <Route path="/grilla" component={Grilla} />
                <Route path="/grilla-mobile" component={GrillaMobile} />
                <Route path="/socios" component={Socios} />
                <Route path="/login" component={Login} />
                <Route path="/socio-pagos" component={SocioPagos} />
                <Route path="/ingresos" component={Ingresos} />
                <Route path="/gastos" component={Gastos} />
                <Route path="/mis-actividades" component={MisActividades} />
                <Route path="/mis-actividades/:dni" component={MisActividades} />
                <Route path="/morosos" component={Morosos} />
                <Route path="/reporte" component={Reporte} />

                <Route path="/categoria/:id" component={Categoria} />
                <Route path="/inscripcion/:id" component={Inscripcion} />
                <Route path="/actividades" component={Actividades} />
                <Route path="/actividad/:id" component={Actividad} />

                <Route path="/crear-actividad" component={CrearActividad} />
                <Route path="/editar-actividad/:id" component={EditarActividad} />




                <Redirect from="/" to="/home" />
                
            </Switch>
        </BrowserRouter>
    // </Provider>
);

ReactDOM.render(Root, document.getElementById('root'));