import React, { Fragment } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import MaterialTable from 'material-table';
import { FaWhatsapp } from 'react-icons/fa'; // Ícono de WhatsApp desde react-icons

function Page(props) {
    const {
        // goTo,
        // currentItem,
    } = props;

    const [state, setState] = React.useState({
      columns: [
        { title: 'Id', field: 'id', editable: 'never' },
        { title: 'Nombre', field: 'nombre' },
        { title: 'Apellido', field: 'apellido' },
        { title: 'DNI', field: 'dni', type: 'numeric' },
        {
          title: 'Notas',
          field: 'notas'
        },
        { title: 'Celular', field: 'celular', type: 'numeric' },
        { title: 'Email', field: 'email' },
        { title: 'Nacimiento', field: 'fechaNacimiento', type: 'date' },
        { title: 'Genero', field: 'genero', lookup: { 'masculino':'Masculino', 'femenino' :'Femenino' ,'otros' :'Otros' } },
        { title: 'Fecha Ingreso', field: 'fechaIngreso', editable: 'never'  }

      ],
      data: props.socios,
    });
  
    const openWhatsApp = (phoneNumber) => {
      // Sanitizar el número de teléfono para que solo queden los dígitos
      const sanitizedNumber = phoneNumber.replace(/\D/g, '');
    
      // Crear la URL de wa.me con el número de teléfono
      const whatsappUrl = `https://wa.me/${sanitizedNumber}`;
    
      // Abrir en una nueva pestaña
      window.open(whatsappUrl, '_blank');
    };
    return (
        <Fragment>
            <CssBaseline />
            <div className="details-page" style={{ marginTop:'5%',display: 'flex',flexWrap: 'wrap'}}>

            <div class="break" style={{flexBasis: '100%'}}></div> 
            <div class="break" style={{flexBasis: '100%'}}></div> 
            <MaterialTable
      title="Socios"
      columns={state.columns}
      data={state.data}
      style={{width:'100%'}}
      localization={{ body: { editRow: { deleteText: '¿Estás seguro de eliminar al socio?' } } }}
      actions={[
        {
          icon: 'payment',
          tooltip: 'Ver pagos',
          onClick: (event, rowData) => {window.location.href = "/socio-pagos?idSocio="+rowData.id+"&nombre="+rowData.nombre+" "+rowData.apellido}
        },
        rowData => ({
          icon: () => <FaWhatsapp color={!rowData.celular || rowData.celular == 'undefined'? 'gray' : 'green'}  />, // Ícono de WhatsApp
          tooltip:  !rowData.celular || rowData.celular == 'undefined'? 'WhatsApp no disponible' : 'Abrir WhatsApp',
          onClick: (event, rowData) => {openWhatsApp(rowData.celular)},
          disabled: !rowData.celular || rowData.celular == 'undefined'
        })
      ]}
      editable={{
        onRowAdd: (newData) =>
          new Promise((resolve,reject) => {
            setTimeout(() => {
                            
              // if(!newData.name){
              //   reject();
              //   alert('flaco la puta madre, pone un nombre');
              //   return;
              // }
              var data = props.onCreate(newData);
              newData.id = data.id;
              resolve();
              setState((prevState) => {
                const data = [...prevState.data];
                data.push(newData);
                return { ...prevState, data };
              });
            }, 600);
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              props.onUpdate(newData);
              resolve();
              if (oldData) {
                setState((prevState) => {
                  const data = [...prevState.data];
                  data[data.indexOf(oldData)] = newData;
                  return { ...prevState, data };
                });
              }
            }, 600);
          }),
        onRowDelete: (oldData) =>
          new Promise((resolve) => {
            setTimeout(() => {
              props.onDelete(oldData.id);
              resolve();
              setState((prevState) => {
                const data = [...prevState.data];
                data.splice(data.indexOf(oldData), 1);
                return { ...prevState, data };
              });
            }, 600);
          }),
      }}
    />
            </div>
        </Fragment>
    );
}

export default Page;